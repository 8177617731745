import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        alignItems: 'center',
        height: "30rem",
        fontFamily:'Helvetica',
        fontSize:7,
        color:'white',
        textAlign: 'center'

    },
    medicine: {
        width: '80%',
        fontSize:8,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    result: {
        width: '20%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:8,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    }
  });

  const InvoiceTableHeader = ({data}) => {
      console.log(data)
      console.log(data.MB_Germ2)
      console.log(data.MB_Germ2!="")
      console.log(data.MB_Germ3!="")
    return <View style={styles.container}>
        <Text style={{
            width:(data.MB_Germ1!="" && data.MB_Germ2!="" && data.MB_Germ3!="" && data.MB_Germ4!="" && data.MB_Germ5!="") ? '25%' : (data.MB_Germ1!="" && data.MB_Germ2!="" && data.MB_Germ3!="" && data.MB_Germ4!="" && data.MB_Germ5=="") ? "40%" : (data.MB_Germ1!="" && data.MB_Germ2!="" && data.MB_Germ3!="" && data.MB_Germ4=="" && data.MB_Germ5=="") ? '55%' : (data.MB_Germ1!="" && data.MB_Germ2!="" && data.MB_Germ3=="" && data.MB_Germ4=="" && data.MB_Germ5=="")  ? '70%' : (data.MB_Germ1!="" && data.MB_Germ2=="" && data.MB_Germ3=="" && data.MB_Germ4=="" && data.MB_Germ5=="") ? "80%" : "85%",
            fontSize:10,
            paddingTop:3,
            height:"100%",
            borderWidth:1,
            fontFamily: 'Helvetica-Bold',
            borderColor:"white"
        }}>Medicine Name</Text>
        <Text style={{
            width: (data.MB_Germ1!="" && data.MB_Germ2=="") ? '20%' :"15%",
            borderLeftColor: borderColor,
            borderLeftWidth: 0.5,
            fontSize:8,
            // 6-sep-2024 Header wala text line k sath lga hua tha us k lye paddingLet ,right lgae thi khizer ne bheji report 8002590
            paddingLeft:0.5,
            paddingRight:0.5,
            paddingTop:3,
            height:"100%",
            borderWidth:1,
            borderColor:"white"

            }}>{(data.MB_Germ1!="" && data.MB_Germ2=="") ? "Result" : data.MB_Germ1}</Text>
            {
            data.MB_Germ2!=""
            ?
            <Text style={{

            width: '15%',
            borderLeftColor: borderColor,
            borderLeftWidth: 0.5,
            fontSize:8,
            paddingTop:3,
            height:"100%",
            borderWidth:1,
            borderColor:"white"

            }}>{data.MB_Germ2}</Text>
            :null
            }
            {
            data.MB_Germ3!=""
            ?
            <Text style={{

                width: '15%',
                borderLeftColor: borderColor,
                borderLeftWidth: 0.5,
                fontSize:8,
                paddingTop:3,
                height:"100%",
                borderWidth:1,
                borderColor:"white"

            }}>{data.MB_Germ3}</Text>
            :null
            }
            {
            data.MB_Germ4!=""
            ?
            <Text style={{

                width: '15%',
                borderLeftColor: borderColor,
                borderLeftWidth: 0.5,
                fontSize:8,
                paddingTop:3,
                height:"100%",
                borderWidth:1,
                borderColor:"white"

            }}>{data.MB_Germ4}</Text>
            :null
            }
            {
            data.MB_Germ5!=""
            ?
            <Text style={{

                width: '15%',
                borderLeftColor: borderColor,
                borderLeftWidth: 0.5,
                fontSize:8,
                paddingTop:3,
                height:"100%",
                borderWidth:1,
                borderColor:"white"
            }}>{data.MB_Germ5}</Text>
            :null
        }
       
  
    </View>
  }
  
  export default InvoiceTableHeader