import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../mplLogoNew30-07-24.jpeg'
import footer from '../MPI New Reproting Ietterhead 30-07-2024 Footer.jpeg'

import CovidPDF from '../Covid_PDF/CovidTemplate'
import Microbiology from '../Microbiology/Report'
import Histopathology from '../Histopathology/Report'
import InvoiceTitleNewGroup from './InvoiceTitleNewGroup';
import InvoiceTitleNewGroupLine from './InvoiceTitleNewGroupLine';

Font.register({ family: 'Calibri Regular', src: require('../../fonts/Calibri Regular.ttf'), });

const styles = StyleSheet.create({
    page: {
        paddingTop: 130,
        paddingBottom: 100,
        fontSize: 8,
        fontFamily: "Helvetica",
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    qrcode: {
        width: 60,
        height: 60
    },
    logo: {
        width: 180,
        height: 100
    },
    barcode: {
        width: 130,
        height: 30,
    }
});


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
const Report = ({ invoice }) => {
    const [numPages, setNumPages] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(null)
    var title = Object.keys(invoice.list).map((key) => {
        return key
    })

    return (<Page
                        style={{
                            paddingTop: 135,
                            paddingBottom: 110,
                            fontSize: 8,
                            paddingLeft: 20,
                            paddingRight: 20,
                            lineHeight: 1.5,
                        }}
                        size="A4"
                        wrap={true}
                    >
                          <Text fixed style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 5,
                            width:"100%",
                            marginTop:12,
                            marginLeft:30,
                            textAlign:"right",
                            marginBottom: 1,
                            position:"absolute"

                        }}>{"DOC No: MPL-GEN-F026 | Issue No: 01 | Issue Date: 04-01-2021"}</Text>
                           {/* all commint and patientimge!==null for show patient image in pdf */}

                           <View fixed style={{
                            width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                            paddingLeft: 20,
                            alignItems: "center",
                            fontFamily: "Helvetica",
                            paddingRight: 20,
                           
                            paddingTop:invoice.PatientImage !==null? 15:10,
                            paddingBottom:5,
                            // paddingTop:10,
                            // backgroundColor:"green"
                        }} >
                         
                            <Image style={[styles.logo,{height: 100,width:178,marginTop:invoice.PatientImage !==null?-20:0}]} src={logo} />


                            {/* 15-july-2023 marginLeft:51 tha now 35 kiya for settelment of pdf heading mid me ni thi */}
                            {/* 27-july-2023 invoice.PatientImage !==null?0:-10 -5 kr dya */}
                         
                            <View style={{ marginLeft: 35,marginTop:invoice.PatientImage !==null?0:-5, flexDirection: "column", alignItems: "center" ,
                            // backgroundColor:"grey"
                            }}>
                                <Image style={[styles.barcode,{height:invoice.PatientImage !==null?25:30}]} src={invoice.barcode} />
                               
                              
                                <View style={{
                                    // margin: 'auto', width: 80, 
                                    // backgroundColor: 'red',
                                    // borderWidth: 1,
                                    // borderColor: 'black',
                                    // marginTop: 20
                                    margin: 'auto', 
                                    // width: 80,
                                    width:invoice.PatientImage !==null?75:80, 
                                    backgroundColor: '#F2F0F0',
                                    // 27-july-2023
                                    // borderWidth: 1,
                                    borderWidth: 0.5,
                                    borderColor: 'black',
                                    // marginTop: 20
                                // 27-july-2023 marginTop:8.5 change to 5 and 20 to 10, 

                                    marginTop:invoice.PatientImage !==null?5:10
                                }}>
                                  
                                    <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize:invoice.PatientImage !==null? 7.5:8.5,
                                // fontSize:8.5
                                }}>MPL ID : {invoice.invoice_id}</Text>
                                </View>
                                {/* 27-july-2023 */}
                                <View style={{
                                    
                                    margin: 'auto', 
                                    // width: 80,
                                    width:invoice.PatientImage !==null?75:80, 
            
                                    marginTop:invoice.PatientImage !==null?2:5
                                }}>
                                <Text style={{  textAlign: 'center', fontSize:invoice.PatientImage !==null? 7.5:8.5,
                                // fontSize:8.5
                                }}>MR No : {invoice.patient_id}</Text>
                                </View>
                                {/* 27-july-2023 marginTop:8.5 change, */}
                                {invoice.PatientImage !==null  ? <View style={{height:65,width:75,marginTop:2,}}>
                        <Image height={800} width={200}  src={invoice.PatientImage} />

                            </View>:<></>}

                            </View>
                            {/* <View style={{ marginLeft: 27.5, marginTop: 5 }}  > */}
                            <View style={{ marginLeft: 32, marginTop:invoice.PatientImage !==null? -10:5 }}  >
                        {/* <View fixed style={{
                            width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                            paddingLeft: 20,
                            alignItems: "center",
                            fontFamily: "Helvetica",
                            paddingRight: 20,
                            paddingTop: 10,
                            // backgroundColor:"red"
                        }} >
                            <Image style={styles.logo} src={logo} />

                            <View style={{ marginLeft: 50,marginTop:-10, flexDirection: "column", alignItems: "center" }}>
                                <Image style={styles.barcode} src={invoice.barcode} />
                                <View style={{
                                    margin: 'auto', width: 80, 
                                    backgroundColor: '#F2F0F0',
                                    borderWidth: 1,
                                    borderColor: 'black',
                                    marginTop: 20
                                }}>
                                    <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 8.5 }}>MPL ID : {invoice.invoice_id}</Text>
                                </View>


                            </View>
                            <View style={{ marginLeft: 30, marginTop: 5 }}  > */}


                                <Text style={{
                                    marginLeft: '5em',
                                    width: "170em",
                                    fontFamily: 'Helvetica-Bold',
                                    fontSize: 9,
                                    marginTop: 5,
                                }}>{invoice.name}</Text>
                                 {/*  for new MR panel 3-JAN-2023 */}
                                 {
                                    invoice.father_husband == "null"
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Father / Husband</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            {/* <Text style={{ width: "45%" }}>{invoice.father_husband}</Text> */}
                                            {invoice.invoice_id==2101409 || invoice.invoice_id==2701404|| invoice.invoice_id==1901385 ? <Text style={{ width: "45%" }}>N/A</Text>: <Text style={{ width: "45%" }}>{invoice.father_husband}</Text>}
                                                {/* <Text style={{ width: "45%" }}>{invoice.age_gender.split('|')[1]}</Text> */}
                                        </View>
                                }
                                {
                                    invoice.cnic == "null"
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>CNIC</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{invoice.cnic}</Text>
                                        </View>
                                }
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderTopWidth: 0.3,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                 <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>{invoice.age_gender.split('|')[0]}</Text>
                                                <Text style={{ width: "10%" }}>:</Text>
                                                <Text style={{ width: "45%" }}>{invoice.age_gender.split('|')[1]}</Text>
                                </View>
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Ref By</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice.Ref_By}</Text>
                                </View>

                                {
                                    invoice.ReferenceNo == ""
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Reference No</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{invoice.ReferenceNo}</Text>
                                        </View>
                                }
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Specimen Date/Time</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice.speicmenDate[0] + " " + invoice.specimenTime[0]}</Text>
                                </View>

                                {/* <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Result Date/Time</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice.ResultDate[0] + " " + invoice.ResultTime[0]}</Text>
                                </View> */}


                            </View>
                            {  invoice.GroupTitleNew=="" ? null : <InvoiceTitleNewGroup title={invoice} />}
                           
                            {/* {  invoice.GroupTitleNew==null ? null : <InvoiceTitleNewGroup title={invoice.GroupTitleNew} />} */}

                            {/* <InvoiceTableHeader data={invoice.Hematology[title][0]}  /> */}

                        </View>
                        {  invoice.GroupTitleNew=="" ? null : <InvoiceTitleNewGroupLine title={invoice} titleLength={invoice.GroupTitleNew.length}/>}
                        {console.log("Hematology 214 numpages :",numPages)}
                        
                        <InvoiceItemsTable invoice={invoice}
                            pageNumbers={numPages}
                            totalPage={totalPages}
                        />
                        <View fixed style={{ position: 'absolute', width: '100%', bottom: 0, }}>


                            <View style={{ marginTop: 0, }}>
{/* top:10 and bottom 0 the wo sary -10 -8 -6 -4 -2 kiye hn 8 dec 2022 8401331 k lye */}
{/* 24-july-2023 Top:10 li jga -15 kiya  */}
                                <Text  fixed style={{ width: "100%", left: 0, paddingLeft: 50, position: "absolute", fontSize: 6,top:-15 }} render={({ pageNumber, totalPages }) => (
                             
                              "Page " + `${pageNumber} / ${totalPages}`
                            // console.log("Hematology 228 : ",pageNumber+"#"+ totalPages)
                                )}
                                 />
                                 
                                {/* <Text style={{ width: "100%", left: 0, paddingLeft: 50, position: "absolute", fontSize: 6 }}>{invoice.numPages}/{invoice.totalPage}</Text> */}
                                 {console.log("Hematology 230 num pages",numPages)}
                                {/* <View style={{ alignItems: 'flex-end', marginRight:-20, }}>
                                    <Text style={{ fontSize: 6 ,bottom: -10,}}>User ID : {capitalizeFirstLetter(invoice.DoctorSignature)} Printed on Date   {invoice.TodayDate} at {invoice.TodayTime}   Inv.Date: {invoice.InvoiceDate[0]}  Inv.Time {invoice.InvoiceTime[0]}</Text>
                                    <Text style={{ color: 'red', bottom: -8, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                    
                                    {
                                        invoice.DoctorSignature.includes("Waqar")||invoice.DoctorSignature.includes("Najam") ?
                                            <View style={{alignItems: 'flex-end'}}>
                                                <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                                <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                                <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                            </View>
                                            :
                                            <View style={{alignItems: 'flex-end'}}>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>

                                        </View>
                                    //     <View style={{alignItems: 'flex-end'}}>
                                    //     <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                    //     <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                    //     <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                    // </View>

                                    }
                                </View> */}
                            </View>


                            <View>
                                          {/* 12-aug-2024 fro new footer 30-07-2024 */}
                            {/* <Image src={footer} style={{ width: "108%", height: 100,}} /> */}
                                
                            <Image src={footer} style={{ width: "107.5%", height: 100,marginLeft:-0.5 }} />
                            </View>
                        </View>

                    </Page>
                   
    )
}

export default Report